<script>
  import { onMount } from 'svelte';

  import { Checkbox, FormGroup, InlineNotification, TextArea } from 'carbon-components-svelte';
  import ipRegex from 'ip-regex';
  import { useLocation, useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import { ComboBox, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import LoadingButton from '../../components/LoadingButton.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';

  import { appConfig } from '../../stores';
  import { createGroup, getOrganizations } from '../../services';
  import { getServerErrorMessage, rowContainsText } from '../../utils';

  let formSubmitMessage;
  let pageData = { loading: true, organizations: [] };

  const {
    flags: { atfEnabled },
  } = $appConfig.data;
  const ipValidator = ipRegex({ exact: true });
  const location = useLocation();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    organizationId: yup.string().required('Enter the organization name!'),
    name: yup.string().required('Enter the group name!'),
    shortName: yup.string(),
    aliases: yup.string(),
    sourceIpAddresses: yup
      .string()
      .nullable()
      .test('valid-ip', 'All entries must be valid IP addresses!', (value) =>
        value ? value.split(',').every((entry) => ipValidator.test(entry.trim())) : true
      ),
    addIpToAuthorizedKeys: yup.boolean(),
    sftp05Username: yup.string().nullable(),
    atfUsername: yup
      .string()
      .nullable()
      .matches(
        /^[\w][\w.-]{2,48}[\w]$/,
        'Username must start and end with letter, number, or underscore and can also contain the following in-between: . -'
      )
      .matches(/^(?!.*\.\.)/, 'Username cannot contain consecutive periods (..)')
      .min(3, 'Username must be at least 3 characters')
      .max(50, 'Username must be at most 50 characters long'),
    notes: yup.string(),
  });

  onMount(async () => {
    try {
      const organizations = (await getOrganizations()).map((org) => ({ ...org, text: org.name }));
      pageData = { organizations, loading: false };
    } catch (error) {
      console.error('[CreateGroup] Failed to retrieve organizations!', error);
      pageData = { loading: false, error };
    }
  });

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;

    try {
      const { id } = await createGroup(values);

      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Group created! Redirecting...',
      };
      setTimeout(() => navigate(`/group/${id}`), 2500);
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Verify your submission and try again.';
      console.error('[CreateGroup] Failed to create group data!', error);
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to create group! ${errorMessage}`,
      };
      setSubmitting(false);
    }
  }

  function shouldFilterOrganization(organization, value) {
    if (!value || value.length < 2) {
      return true;
    }

    return rowContainsText(organization, value);
  }

  $: initialValues = {
    organizationId: new URLSearchParams($location.search)?.get('organizationId') ?? '',
    addIpToAuthorizedKeys: false,
    atfUsername: null,
  };
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load your access permissions! Please try again later."
  />
{:else}
  {#if formSubmitMessage}
    <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
  {/if}
  <Form
    fieldDomString="input,textarea,select,div[name='organizationId']"
    {initialValues}
    validateOnBlur={true}
    validateOnChange={true}
    {schema}
    on:submit={onFormSubmit}
    let:submitForm
    let:isSubmitting
    let:setValue
    let:errors
    let:touched
    let:values
  >
    <FormGroup>
      <ComboBox
        helperText="Select the parent organization for this group. Organizations can contain multiple groups."
        invalid={touched.organizationId && !!errors.organizationId}
        invalidText={errors.organizationId}
        items={pageData.organizations}
        name="organizationId"
        placeholder="Organization name"
        selectedId={initialValues.organizationId}
        shouldFilterItem={shouldFilterOrganization}
        titleText="Organization"
        required
        on:clear={() => setValue('organizationId', '')}
        on:select={({ detail: { selectedId } }) => setValue('organizationId', selectedId)}
      />
      <TextInput
        helperText="Capitalized, user-friendly name unless purposely stylized otherwise by the customer. Example: Client Services."
        invalid={touched.name && !!errors.name}
        invalidText={errors.name}
        labelText="Group Name"
        name="name"
        placeholder="Group name"
        type="text"
        value={values.name}
        required
        on:change={({ detail: text }) => setValue('name', text)}
      />
      <TextInput
        helperText="Used to uniquely identify the group in email chains and Jenkins. Example: mst_cs."
        invalid={touched.shortName && !!errors.shortName}
        invalidText={errors.shortName}
        labelText="Short Name"
        name="shortName"
        placeholder="Short name"
        type="text"
        value={values.shortName}
        on:change={({ detail: text }) => setValue('shortName', text)}
      />
      <TextInput
        helperText="Alternative or previous names for this group."
        invalid={touched.aliases && !!errors.aliases}
        invalidText={errors.aliases}
        labelText="Aliases"
        name="aliases"
        placeholder="Comma-separated aliases"
        type="text"
        value={values.aliases}
        on:change={({ detail: text }) => setValue('aliases', text)}
      />
      <h3 class="column-subheading">SFTP05</h3>
      <TextInput
        invalid={touched.sftp05Username && !!errors.sftp05Username}
        invalidText={errors.sftp05Username}
        labelText="SFTP05 Username"
        name="sftp05Username"
        placeholder="SFTP05 username"
        type="text"
        value={values.sftp05Username}
        on:change={({ detail: text }) => setValue('sftp05Username', text)}
      />
      <TextInput
        helperText="Use commas to separate entries."
        invalid={touched.sourceIpAddresses && !!errors.sourceIpAddresses}
        invalidText={errors.sourceIpAddresses}
        labelText="Source IP Addresses"
        name="sourceIpAddresses"
        placeholder="Source IP addresses"
        type="text"
        value={values.sourceIpAddresses}
        on:change={({ detail: text }) => setValue('sourceIpAddresses', text)}
      />
      <Checkbox
        name="addIpToAuthorizedKeys"
        labelText="Add source IP Addresses to authorized keys"
        checked={values.addIpToAuthorizedKeys}
        on:check={({ detail }) => setValue('addIpToAuthorizedKeys', detail)}
      />
      {#if atfEnabled}
        <h3 class="column-subheading">AWS Transfer Family</h3>
        <TextInput
          invalid={touched.atfUsername && !!errors.atfUsername}
          invalidText={errors.atfUsername}
          labelText="ATF Username"
          name="atfUsername"
          placeholder="ATF Username"
          type="text"
          value={values.atfUsername}
          on:change={({ detail: text }) => setValue('atfUsername', text)}
        />
      {/if}
      <h3 class="column-subheading">Miscellaneous</h3>
      <TextArea
        invalid={touched.notes && !!errors.notes}
        invalidText={errors.notes}
        labelText="Notes"
        name="notes"
        placeholder="Additional notes"
        type="text"
        value={values.notes}
        on:change={({ target }) => setValue('notes', target.value)}
      />
    </FormGroup>
    <div class="form-controls">
      <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
    </div>
  </Form>
{/if}

<style>
  .column-subheading {
    font-size: 1rem;
    margin-top: 1.25rem;
  }
</style>
