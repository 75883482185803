const permissionTypeDescription = {
  'data-feeds-sftp05': 'Data Feeds (SFTP05)',
  'data-feeds-crushftp': 'Data Feeds (AWS Transfer Family/SFTP05)',
  'data-feeds-lake': 'Data Feeds (AWS Lake)',
  'data-feeds-aws-arbitrated-pcaps': 'Data Feeds (AWS Arbitrated PCAPs)',
  workbench: 'Workbench',
};

module.exports = {
  permissionTypeDescription,
};
