<script>
  import { onMount } from 'svelte';

  import { Button, DataTable, InlineNotification, Link, Modal, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import LoadingSpinner from '../../../components/LoadingSpinner.svelte';
  import PermissionsSummaryModal from '../../../components/PermissionsSummaryModal.svelte';
  import StatusIcon from '../../../components/StatusIcon.svelte';
  import { createGroupPermission, deleteGroupPermission, getGroupPermissions } from '../../../services';
  import { messagesStore } from '../../../stores';
  import { convertToLocalDisplayTime, getServerErrorMessage } from '../../../utils';
  import { isExecutionColliding } from '../../../../shared/taskUtils';

  const PERMISSIONS_WITH_RELATED_TASK = { 'data-feeds-crushftp': 'crushftp-group-reconciliation' };

  export let groupId;

  let confirmationModal;
  let pageData = { loading: true };
  let previewModalOpen;

  const navigate = useNavigate();

  async function loadGroupPermissions() {
    if (!pageData.loading) {
      pageData = { loading: true };
    }

    try {
      const groupPermissions = await getGroupPermissions(groupId);
      pageData = {
        groupPermissions: groupPermissions.filter(({ type }) => type !== 'data-feeds-crushftp' && type !== 'data-feeds-sftp05'),
        loading: false,
      };
    } catch (error) {
      console.error('[GroupPermissions] Failed to load group permissions!', error);
      pageData = { ...pageData, error, loading: false };
    }
  }

  async function grantPermission(type) {
    pageData = { ...pageData, error: undefined, loading: true };
    try {
      await createGroupPermission({ groupId, type });
      loadGroupPermissions();
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) || 'Failed to create group permission!';
      console.error('[GroupPermissions] Failed to create permission!', errorMessage);
      pageData = { ...pageData, error: errorMessage, loading: false };
    }
  }

  async function revokePermission(permissionId, permissionType) {
    pageData = { ...pageData, error: undefined, loading: true };
    try {
      await deleteGroupPermission(permissionId, permissionType);
      loadGroupPermissions();
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) || 'Failed to remove permission!';
      console.error('[GroupPermissionsList] Failed to remove group permission!', errorMessage);
      pageData = { ...pageData, error: errorMessage, loading: false };
    }
  }

  function openConfirmationModal(row) {
    const isEnabled = row.deletedAt || row.stub;
    confirmationModal = {
      isEnabled,
      onSubmit: isEnabled ? () => grantPermission(row.type) : () => revokePermission(row.id, row.type),
    };
  }

  function onToggleSubmit() {
    confirmationModal.onSubmit();
    confirmationModal = null;
  }

  function togglePreviewModal() {
    previewModalOpen = !previewModalOpen;
  }

  function viewPermissionDetails(event, permissionId, permissionType) {
    event.preventDefault();
    navigate(`/group/${groupId}?permissionId=${permissionId}#permissions-${permissionType}`);
  }

  function closeModal() {
    confirmationModal = null;
  }

  onMount(loadGroupPermissions);

  $: permissionsWithRunningTasks = Object.keys(PERMISSIONS_WITH_RELATED_TASK).filter((permissionType) => {
    return isExecutionColliding(
      { relatedEntityId: groupId, taskId: PERMISSIONS_WITH_RELATED_TASK[permissionType] },
      $messagesStore.runningTaskExecutions
    );
  });
</script>

{#if pageData.error}
  <InlineNotification hideCloseButton kind="error" lowContrast title="Error:" subtitle={pageData.error} />
{/if}

<LoadingSpinner loading={pageData.loading}>
  <DataTable
    headers={[
      { key: 'typeDescription', value: 'Type' },
      { key: 'deletedAt', value: 'Enabled' },
      { key: 'createdAt', value: 'Created', display: (val) => (val ? convertToLocalDisplayTime(val) : '—') },
      { key: 'updatedAt', value: 'Updated', display: (val) => (val ? convertToLocalDisplayTime(val) : '—') },
      { key: 'updatedByDisplayName', value: 'Updated By', display: (val) => val ?? '—' },
      { key: 'actions', empty: true, sort: false },
    ]}
    rows={pageData.groupPermissions ?? []}
    sortable
  >
    <span slot="cell" let:cell let:row>
      {#if cell.key === 'typeDescription'}
        <div class="type-description-wrapper">
          <Link
            href={`/group/${groupId}?permissionId=${row.id}#permissions-${row.type}`}
            on:click={(event) => viewPermissionDetails(event, row.id, row.type)}
          >
            {row.typeDescription}
          </Link>
          {#if permissionsWithRunningTasks.includes(row.type)}
            <LoadingSpinner
              withOverlay={false}
              centered={false}
              compact
              description="There are running tasks that are related to this permission"
              inlineLeftMargin
            />
          {/if}
        </div>
      {:else if cell.key === 'actions'}
        <Button
          class="permission-list-toggle-button"
          size="sm"
          on:click={() => openConfirmationModal(row)}
          kind={row.deletedAt === null ? 'danger-tertiary' : 'tertiary'}
        >
          {row.deletedAt ? 'Enable' : row.stub ? 'Create' : 'Disable'} permission
        </Button>
      {:else if cell.key === 'deletedAt'}
        <StatusIcon valid={!row.stub && !row.deletedAt} />
      {:else if cell.display}
        {cell.display(cell.value, row)}
      {:else}
        {cell.value}
      {/if}
    </span>
    <Toolbar>
      <ToolbarContent>
        <Button kind="primary" size="small" on:click={togglePreviewModal}>Preview permissions</Button>
      </ToolbarContent>
    </Toolbar>
  </DataTable>
</LoadingSpinner>

<PermissionsSummaryModal entityId={groupId} open={previewModalOpen} on:close={togglePreviewModal} />

<Modal
  modalHeading="Confirm Action"
  preventCloseOnClickOutside={true}
  primaryButtonText={confirmationModal?.isEnabled ? 'Enable' : 'Disable'}
  secondaryButtonText="Cancel"
  size="sm"
  danger={!confirmationModal?.isEnabled}
  bind:open={confirmationModal}
  on:submit={onToggleSubmit}
  on:close={closeModal}
  on:click:button--secondary={closeModal}
>
  {#if confirmationModal?.isEnabled}
    <p>Are you sure you want to enable this permission?</p>
  {:else}
    <p>Are you sure you want to disable this permission?</p>
  {/if}
</Modal>

<style>
  .type-description-wrapper {
    display: inline-flex;
  }

  :global(.permission-list-toggle-button) {
    width: 10rem;
    padding: 10px !important;
    display: flex;
    justify-content: center;
  }
</style>
