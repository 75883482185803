<script>
  import { onMount, setContext } from 'svelte';

  import { InlineNotification, TabContent } from 'carbon-components-svelte';

  import AuditTable from '../../components/audit-table/AuditTable.svelte';
  import LazyLoadedTabContent from '../../components/LazyLoadedTabContent.svelte';
  import GroupBasicInformation from './GroupBasicInformation.svelte';
  import GroupExternalKeyList from './GroupExternalKeyList.svelte';
  import GroupKeyList from './GroupKeyList.svelte';
  import GroupLicenseList from './GroupLicenseList.svelte';
  import GroupPermissionsRouter from './group-permissions/GroupPermissionsRouter.svelte';
  import GroupCloudPrincipalsList from './GroupCloudPrincipalsList.svelte';
  import GroupTargetList from './GroupTargetList.svelte';
  import GroupUserList from './GroupUserList.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import TabsWithRouting from '../../components/TabsWithRouting.svelte';
  import { getGroup } from '../../services';
  import { cacheEntityName } from '../../stores';

  export let groupId;

  let pageData = { loading: true };

  const loadGroupData = async () => {
    pageData = { ...pageData, loading: true };
    try {
      const group = await getGroup(groupId);
      pageData = { loading: false, group };
      cacheEntityName(groupId, group.name);
    } catch (error) {
      console.error('[GroupDetails] Failed to retrieve group data!', error);
      pageData = { loading: false, error };
    }
  };

  onMount(loadGroupData);

  $: setContext('group', pageData.group);
</script>

{#if pageData.loading}
  <LoadingSpinner withOverlay={false} />
{:else if pageData.error}
  <InlineNotification
    hideCloseButton
    kind="error"
    lowContrast
    title="Error:"
    subtitle="Failed to load group data! Please try again later."
  />
{/if}

{#if !pageData.loading || pageData.group}
  <div class="group-tabs">
    <TabsWithRouting
      defaultHash={'#basic-information'}
      tabsWrapperProps={{ 'aria-label': 'Group details tabs' }}
      tabs={[
        { label: 'Basic Information', hash: '#basic-information' },
        { label: 'Users', hash: '#users' },
        { label: 'Cloud Principals', hash: '#cloud-principals' },
        { label: 'SSH Keys', hash: '#ssh-keys' },
        { label: 'External Services Keys', hash: '#external-keys' },
        { label: 'Build Targets', hash: '#parameters' },
        { label: 'Licenses', hash: '#licenses' },
        { label: 'Permissions', hash: '#permissions', activeForChildHashes: true },
        { label: 'History', hash: '#history' },
      ]}
    >
      <TabContent><GroupBasicInformation {groupId} group={pageData.group} {loadGroupData} /></TabContent>
      <LazyLoadedTabContent hash="#users"><GroupUserList {groupId} organizationId={pageData.group?.organizationId} /></LazyLoadedTabContent>
      <LazyLoadedTabContent hash="#cloud-principals"><GroupCloudPrincipalsList {groupId} /></LazyLoadedTabContent>
      <LazyLoadedTabContent hash="#ssh-keys"><GroupKeyList group={pageData.group} /></LazyLoadedTabContent>
      <LazyLoadedTabContent hash="#external-keys">
        <GroupExternalKeyList {groupId} groupName={pageData.group?.name} />
      </LazyLoadedTabContent>
      <LazyLoadedTabContent hash="#parameters"><GroupTargetList groupName={pageData.group?.name} {groupId} /></LazyLoadedTabContent>
      <LazyLoadedTabContent hash="#licenses">
        <GroupLicenseList
          {groupId}
          shortName={pageData.group?.shortName}
          licenseLookbackDays={pageData.group?.licenseLookbackDays}
          groupName={pageData.group?.name}
          sftp05Username={pageData.group.sftp05Username}
          atfUsername={pageData.group.atfUsername}
        />
      </LazyLoadedTabContent>
      <TabContent><GroupPermissionsRouter {groupId} groupName={pageData.group?.name} /></TabContent>
      <LazyLoadedTabContent hash="#history"><AuditTable entityId={groupId} entityType="group" /></LazyLoadedTabContent>
    </TabsWithRouting>
  </div>
{/if}

<style>
  /* 4th child is external-keys with a longer than avg text */
  .group-tabs :global(.bx--tabs__nav-item:nth-child(4) .bx--tabs__nav-link) {
    width: 13rem;
  }
</style>
