<script context="module">
  export const BASIC_INFO_LABELS = {
    name: 'Name',
    cycle: 'Cycle',
    diff: 'Diff',
    hosts: 'Host IDs',
    emails: 'Notify',
    destinations: 'Upload To',
    isDemo: 'Demo',
  };

  export const SUPPORTED_RENEWAL_CYCLES = {
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    yearly: 'Yearly',
    once: 'One-time',
  };
</script>

<script>
  import truncate from 'lodash/truncate';
  import * as yup from 'yup';

  import { Button, Link, Tag } from 'carbon-components-svelte';
  import { Checkbox, FormGroup, NumberInput, Select, SelectItem, TextArea, TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import AddIcon from 'carbon-icons-svelte/lib/Add16';
  import EditIcon from 'carbon-icons-svelte/lib/Edit16';

  import DestinationLabel from './DestinationLabel.svelte';
  import { appConfig } from '../../stores';

  const MESSAGES = {
    warningSelections: 'Typed destination is already under selections',
  };
  const INITIAL_UPLOAD_STATE = {
    destination: Object.keys($appConfig.data.supportedUploadDestinations)[0],
    path: '',
    issue: '',
  };

  export let defaultDestinations,
    edit,
    initialValues = {
      cycle: Object.keys(SUPPORTED_RENEWAL_CYCLES)[0],
      hosts: 'product=bellport version=1.0',
      diff: 0,
      destinations: defaultDestinations,
      isDemo: false,
    };

  let editState = { diff: edit, hosts: edit, destination: !defaultDestinations?.length },
    uploadState = { ...INITIAL_UPLOAD_STATE };

  // handles native change event
  function handleChangeEvent({ key, setValue }) {
    return async function onChange({ target: { value } }) {
      setValue(key, value);
    };
  }

  // handles change events dispatched by svelte
  function handleCustomChangeEvent({ key, setValue, previousValue, values, touched }) {
    return function onCustomChange({ detail }) {
      if (previousValue === detail) {
        return;
      }

      if (key === 'isDemo' && touched.isDemo) {
        const nameIncludesDemo = values?.name.toLocaleLowerCase().includes('demo');

        if (detail) {
          if (!nameIncludesDemo) {
            setValue('name', values?.name ? `DEMO ${values.name}` : 'DEMO');
          }

          setValue('cycle', 'once');
        } else if (nameIncludesDemo) {
          setValue('name', values.name.replace(/demo/i, '').trim());
        }
      }

      setValue(key, detail);
    };
  }

  function handleUploadState(key) {
    return function onUploadStateChange({ detail: value }) {
      uploadState = {
        ...uploadState,
        [key]: value,
      };
    };
  }

  function handleDestinationAddition({ key, setValue, values }) {
    const pathToAdd = `${uploadState.destination}:${uploadState.path}`;

    if (values.includes(pathToAdd)) {
      uploadState = {
        ...uploadState,
        issue: 'warning',
      };
      return;
    }

    const newValues = [...values, pathToAdd];
    setValue(key, newValues);
    uploadState = { ...INITIAL_UPLOAD_STATE };
  }

  function handleDestinationRemoval({ key, setValue, valueToRemove, values }) {
    const newValues = values.filter((value) => value !== valueToRemove);
    setValue(key, newValues);
  }

  function changeSelectionStatus(field) {
    return function handleStatusChange() {
      editState = { ...editState, [field]: !editState[field] };
    };
  }

  function isValidPath(destinationAndPath) {
    const [destination, path] = destinationAndPath.split(':');
    if (destination === 'atf') {
      return !/[<>:"|?*]/.test(path) && /^\/licenses$/.test(path);
    }

    return (
      !/[<>:"|?*]/.test(path) &&
      (/^\/mnt\/prod-nyc3-fs001_customers\/[0-9A-z-_]+\/data\/licenses$/.test(path) ||
        /^\/mnt\/prod-nyc3-fs001_customers\/[0-9A-z-_]+\/upload$/.test(path) ||
        /^\/data\/customers\/[0-9A-z-_]+\/data\/licenses$/.test(path))
    );
  }

  $: schema = yup.object().shape({
    name: yup.string().required('License name cannot be empty'),
    cycle: yup.mixed().oneOf(Object.keys(SUPPORTED_RENEWAL_CYCLES)).required('Please select renewal cycle'),
    diff: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('License diff cannot be empty'),
    hosts: yup.string().required('License hosts cannot be empty'),
    destinations: yup
      .array()
      .of(
        yup.string().test(
          'is-path',
          `Not supported path(s) selected. Path should follow the pattern 
            "/mnt/prod-nyc3-fs001_customers/<anything>/data/licenses" for SFTP05 or "/licenses" for ATF`,
          isValidPath
        )
      )
      .min(1, 'License upload destinations cannot be empty'),
    isDemo: yup.boolean(),
  });
</script>

<div class="form">
  <Form
    fieldDomString="input,textarea,select,div[name]"
    {initialValues}
    {schema}
    let:submitForm
    let:setValue
    let:touched
    let:values
    let:isValid
    let:errors
    on:submit
  >
    <Checkbox
      name="isDemo"
      labelText="Demo license template"
      checked={values.isDemo}
      on:check={handleCustomChangeEvent({ key: 'isDemo', setValue, values, touched })}
    />
    <FormGroup>
      <TextInput
        name="name"
        autocomplete="off"
        invalid={touched.name && !!errors.name}
        invalidText={errors.name}
        labelText={BASIC_INFO_LABELS.name}
        placeholder="License name"
        value={values.name}
        required
        on:change={handleCustomChangeEvent({ key: 'name', setValue })}
      />
      <Select
        name="cycle"
        invalid={touched.cycle && !!errors.cycle}
        invalidText={errors.cycle}
        labelText={BASIC_INFO_LABELS.cycle}
        selected={values.cycle}
        required
        on:change={handleCustomChangeEvent({ key: 'cycle', setValue, previousValue: values.cycle })}
      >
        {#each Object.entries(SUPPORTED_RENEWAL_CYCLES) as [value, text]}
          <SelectItem {value} {text} />
        {/each}
      </Select>
      {#if !editState.diff}
        <div class="view-field">
          <span class="bx--label">Diff</span>
          <div class="value">
            <strong>{values.diff}</strong>
            <div class="link-button">
              <Link on:click={changeSelectionStatus('diff')} icon={EditIcon}>Edit diff</Link>
            </div>
          </div>
        </div>
      {/if}
      <div class="field" class:hidden={!editState.diff}>
        <NumberInput
          name="diff"
          invalid={touched.diff && !!errors.diff}
          invalidText={errors.diff}
          label={BASIC_INFO_LABELS.diff}
          value={values.diff}
          required
          on:change={handleCustomChangeEvent({ key: 'diff', setValue })}
          on:input={handleCustomChangeEvent({ key: 'diff', setValue })}
        />
      </div>
      {#if !editState.hosts}
        <div class="view-field">
          <span class="bx--label">Host IDs</span>
          <div class="value">
            <strong>{truncate(values.hosts)}</strong>
            <div class="link-button">
              <Link on:click={changeSelectionStatus('hosts')} icon={EditIcon}>Edit Host IDs</Link>
            </div>
          </div>
        </div>
      {/if}
      <div class="no-wrap field" class:hidden={!editState.hosts}>
        <TextArea
          name="hosts"
          invalid={touched.hosts && !!errors.hosts}
          invalidText={errors.hosts}
          labelText={BASIC_INFO_LABELS.hosts}
          placeholder="Hosts"
          value={values.hosts}
          required
          on:change={handleChangeEvent({ key: 'hosts', setValue })}
        />
      </div>
    </FormGroup>
    <FormGroup legendText="Upload">
      {#if !editState.destination}
        <div class="link-button">
          <Link on:click={changeSelectionStatus('destination')} icon={EditIcon}>Edit upload destinations</Link>
        </div>
      {/if}
      <div class="upload-controls" class:hidden={!editState.destination}>
        <div class="select-destination">
          <Select labelText="Add destination" selected={uploadState.destination} on:change={handleUploadState('destination')}>
            {#each Object.entries($appConfig.data.supportedUploadDestinations) as [value, text]}
              <SelectItem {value} {text} />
            {/each}
          </Select>
        </div>
        <div class="path-input">
          <TextInput autocomplete="off" value={uploadState.path} on:change={handleUploadState('path')} placeholder="e.g. /home/name" />
        </div>
        <Button
          disabled={!uploadState.destination || !uploadState.path}
          size="field"
          iconDescription="Add path"
          icon={AddIcon}
          on:click={handleDestinationAddition({ key: 'destinations', setValue, values: values.destinations })}
        />
      </div>
      {#if uploadState.issue}
        <span class="{uploadState.issue} bx--label">{MESSAGES[`${uploadState.issue}Selections`]}</span>
      {/if}
      <div class="selections-wrapper">
        <span class="bx--label">Selected destinations</span>
        <div
          class={touched.destinations && !!errors.destinations ? 'error selections' : 'selections'}
          class:read-only={!editState.destination}
          name="destinations"
          aria-label="Selected destinations"
          tabindex="0"
        >
          {#if !values.destinations?.length}
            <span>Use input above to add destinations. Your selections will appear here.</span>
          {:else}
            {#each values.destinations as value}
              <div class="destination-tag">
                <Tag
                  filter={editState.destination}
                  on:close={handleDestinationRemoval({
                    key: 'destinations',
                    setValue,
                    valueToRemove: value,
                    values: values.destinations,
                  })}
                  on:click={(e) => e.preventDefault()}
                >
                  <DestinationLabel fullPath={value} />
                </Tag>
              </div>
            {/each}
          {/if}
        </div>
        {#if touched.destinations && !!errors.destinations}
          <span class="error bx--label">{errors.destinations}</span>
        {/if}
      </div>
    </FormGroup>
    <slot name="submit" {submitForm} {isValid} />
  </Form>
</div>

<style>
  .form :global(.bx--fieldset) {
    margin-bottom: 0.75rem;
  }

  .field,
  .view-field {
    margin-top: 0.75rem;
  }

  .view-field .value {
    display: flex;
  }

  .view-field .value :global(strong) {
    margin-right: 0.5rem;
  }

  .hidden {
    display: none !important;
  }

  .link-button :global(a) {
    cursor: pointer;
  }

  .no-wrap :global(textarea) {
    white-space: nowrap;
  }

  .no-wrap :global(.bx--form-item) {
    margin-top: 0.75rem;
  }

  .upload-controls {
    display: flex;
    align-items: flex-end;
  }

  .upload-controls .select-destination {
    flex: 1.1;
  }

  .upload-controls .path-input {
    margin: 0 0.25rem;
    flex: 2;
  }

  .selections-wrapper {
    margin-top: 0.75rem;
  }

  .selections {
    background-color: var(--cds-field-02);
    padding: 0.5rem;
    min-height: 100px;
    border: 1px solid var(--cds-ui-04);
    outline-offset: -2px;
  }

  .selections.read-only {
    min-height: unset;
  }

  .selections:focus {
    outline: 2px solid var(--cds-focus) !important;
  }

  .selections > span {
    color: var(--cds-text-02);
    font-style: italic;
    font-size: 12px;
  }

  .selections :global(.bx--tag) {
    border: 1px solid var(--cds-ui-04);
  }

  .warning.bx--label {
    color: var(--outline-warning-color);
  }

  .error.bx--label {
    color: var(--cds-support-01);
  }

  .error.selections {
    outline: 2px solid var(--cds-support-01);
  }

  .destination-tag :global(.bx--tag) {
    position: relative;
  }
</style>
