import { ROOT_SERVER_URL } from '../config';
import { request } from '../utils';

export function createGroup(data) {
  return request(`${ROOT_SERVER_URL}/api/groups`, {
    data,
    method: 'POST',
  });
}

export function getGroup(groupId) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}`);
}

export async function getGroups() {
  const rawGroups = await request(`${ROOT_SERVER_URL}/api/groups`);
  return rawGroups.map((group) => ({ ...group, organizationName: group.organization.name }));
}

export function updateGroup(groupId, changes) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}`, {
    data: changes,
    method: 'PUT',
  });
}

export function getGroupUsers(groupId) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}/users`);
}

export function addUsersToGroup(userIds, groupId) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}/users`, {
    method: 'POST',
    data: userIds,
  });
}

export function updateUserGroupRoles(groupId, userId, groupRoleIds) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}/users?userId=${userId}`, {
    data: groupRoleIds,
    method: 'PUT',
  });
}

export function removeUserFromGroup(groupId, userId) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}/users?userId=${userId}`, {
    method: 'DELETE',
  });
}

export async function getGroupAwsPrincipals(groupId) {
  const groupAwsPrincipals = await request(`${ROOT_SERVER_URL}/api/groups/${groupId}/groupAwsPrincipals`);

  return groupAwsPrincipals.map((principal) => {
    return {
      ...principal,
      provider: 'AWS',
      principalIdentifier: principal.principalIdentifier ?? 'N/A',
    };
  });
}

export async function addGroupAwsPrincipal(groupId, { account, principalType, principalIdentifier }) {
  const data = {
    groupId,
    account,
    principalType,
    ...(principalType !== 'root' && principalIdentifier && { principalIdentifier }),
  };
  return await request(`${ROOT_SERVER_URL}/api/group-aws-principals/`, {
    method: 'POST',
    data,
  });
}

export async function removeGroupAwsPrincipal(principalId) {
  return await request(`${ROOT_SERVER_URL}/api/group-aws-principals/${principalId}`, {
    method: 'DELETE',
  });
}

export function deleteGroup(groupId) {
  return request(`${ROOT_SERVER_URL}/api/groups/${groupId}`, {
    method: 'DELETE',
  });
}

export async function syncPrivileges(groupId) {
  return await request(`${ROOT_SERVER_URL}/api/groups/${groupId}/aws/policies/sync`, {
    method: 'POST',
  });
}
