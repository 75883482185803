<script>
  import { onMount } from 'svelte';

  import { Button, DataTable, InlineNotification } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';
  import Warning16 from 'carbon-icons-svelte/lib/Warning16';

  import { IndependentSystemTasks, Sftp05RelatedTasks } from '../../../server/config/constants';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getTasks, triggerTask } from '../../services';
  import { criticalWarningsStore, messagesStore } from '../../stores';
  import { asOptionalTextColumn } from '../../utils';

  let pageData = { loading: true };

  const navigate = useNavigate();

  onMount(async () => {
    try {
      const tasks = await getTasks();
      pageData = {
        // TODO: do we need SFTP05 sync task?
        tasks: tasks.filter(({ id }) => ![IndependentSystemTasks.DATA_INVENTORY_SYNC_SFTP05].includes(id)),
        loading: false,
      };
    } catch (error) {
      console.error('[TaskList] Failed to load tasks!', error);
      pageData = { loading: false, error };
    }
  });

  async function runTask(taskId) {
    pageData = { ...pageData, loading: true };
    try {
      await triggerTask(taskId);
      navigate('/tasks#executions');
      pageData = { ...pageData, loading: false };
    } catch (error) {
      console.error('[TaskList] Failed to trigger!', error);
      pageData = { ...pageData, error, loading: false };
    }
  }

  $: needsSftp05Reconciliation = Boolean($criticalWarningsStore.sftp05OutOfSync);
  $: failedTasks = $messagesStore.failedTaskExecutions?.map(({ taskId }) => taskId);
  $: showFailedTaskWarning = (taskId) => {
    if (taskId === Sftp05RelatedTasks.SFTP05_RECONCILIATION && needsSftp05Reconciliation) {
      return true;
    }
    if (failedTasks?.includes(taskId) && Object.values(IndependentSystemTasks).includes(taskId)) {
      return true;
    }
  };

  $: tasksWithWarning = pageData.tasks?.map((task) => ({ ...task, showWarning: showFailedTaskWarning(task.id) }));
</script>

{#if pageData.error}
  <InlineNotification hideCloseButton kind="error" lowContrast title="Error:" subtitle="Failed to load tasks! Please try again later." />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'description', value: 'Description', ...asOptionalTextColumn },
        { key: 'frequencyDescription', value: 'Frequency', ...asOptionalTextColumn },
        { key: 'actions', empty: true, sort: false },
      ]}
      rows={tasksWithWarning}
      sortable
    >
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'name' && row.showWarning}
          {cell.value} <Warning16 class="failed-task-warning-icon" />
        {:else if cell.key === 'actions' && row.manualExecutionEnabled}
          <Button kind="ghost" on:click={() => runTask(row.id)}>Run task</Button>
        {:else if cell.key === 'actions' && !row.manualExecutionEnabled}
          <!-- Don't render anything -->
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
    </DataTable>
  </LoadingSpinner>
{/if}

<style>
  :global(.failed-task-warning-icon) {
    margin-bottom: -0.2rem;
    color: var(--cds-support-01);
  }
</style>
