<script>
  import { TooltipIcon } from 'carbon-components-svelte';

  export let licenseStatus;
</script>

<div class={licenseStatus.status}>
  <TooltipIcon tooltipText={licenseStatus.message} direction="left" align="start" icon={licenseStatus.icon} />
</div>

<style>
  .warning :global(svg) {
    fill: var(--outline-warning-color);
  }

  .danger :global(svg) {
    fill: var(--cds-danger);
  }

  .expired :global(svg),
  .error :global(svg) {
    fill: var(--cds-active-danger);
  }

  .valid :global(svg) {
    fill: #24a148;
  }
</style>
