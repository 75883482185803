<script>
  import { onMount } from 'svelte';

  import {
    Button,
    InlineNotification,
    NotificationActionButton,
    OverflowMenu,
    OverflowMenuItem,
    Toolbar,
    ToolbarContent,
  } from 'carbon-components-svelte';
  import copy from 'clipboard-copy';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import AddOrEditSshKeyModal from '../../components/ssh-keys/AddOrEditSshKeyModal.svelte';
  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import LongTextTooltipCell from '../../components/LongTextTooltipCell.svelte';
  import RemoveSshKeyModal from '../../components/ssh-keys/RemoveSshKeyModal.svelte';
  import SshKeyAssociationStatus from '../../components/ssh-keys/SshKeyAssociationStatus.svelte';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';

  import { appConfig } from '../../stores';
  import { getSshKeys } from '../../services';
  import { convertToLocalDisplayTime } from '../../utils';

  export let userId;
  export let userFullName;

  const { atfEnabled } = $appConfig.data.flags;
  const notifications = {
    error: {
      kind: 'error',
      title: 'Error:',
      subtitle: 'Failed to load SSH keys for user! Please try again later.',
    },
    noKeys: {
      kind: 'info',
      title: 'Info:',
      subtitle: 'There are no SSH keys for this user.',
      action: {
        text: 'Create key',
        onClick: () => openModal('addOrEditKey'),
      },
    },
  };

  const miscHeaders = [
    { key: 'createdAt', value: 'Created', display: convertToLocalDisplayTime },
    { key: 'updatedAt', value: 'Updated', display: convertToLocalDisplayTime },
    { key: 'actions', empty: true, sort: false },
  ];

  const headers = [
    { key: 'publicKey', value: 'Public Key' },
    { key: 'sftp05Access', value: 'SFTP05 Access' },
    ...(atfEnabled ? [{ key: 'atfAccess', value: 'ATF Access' }, ...miscHeaders] : [...miscHeaders]),
  ];

  let modals = {
    addOrEditKey: { open: false, sshKey: undefined },
    removeKey: { open: false, sshKey: undefined },
  };
  let pageData = { loading: true };
  const query = { pageSize: 25, currentPage: 1 };

  async function loadUserKeys() {
    pageData = { ...pageData, loading: true };
    try {
      const sshKeys = await getSshKeys({ ownerId: userId, ownerType: 'user' });
      pageData = { sshKeys, loading: false, displayedNotification: sshKeys.length === 0 && 'noKeys' };
    } catch (error) {
      console.error('[UserKeyList] Failed to load user SSH keys!', error);
      pageData = { loading: false, displayedNotification: 'error' };
    }
  }

  /**
   * @description Close a given modal by name. Since the modal components may forward the inner Carbon modal's "close"
   *    event multiple times, a single "toggle" method is more prone to unexpected behavior.
   * @param name
   */
  function closeModal(name) {
    modals = {
      ...modals,
      [name]: { ...modals[name], open: false },
    };
  }

  /**
   * @description Open a given modal by name, optionally passing an object of data to forward.
   * @param {string} name
   * @param {object?} forwardData
   */
  function openModal(name, forwardData) {
    modals = {
      ...modals,
      [name]: { open: true, ...forwardData },
    };
  }

  function onCopyPublicKey(sshKeyId) {
    const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
    copy(sshKey.publicKey);
  }

  function onEditKey(sshKeyId) {
    const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
    openModal('addOrEditKey', { sshKey });
  }

  function onRemoveKey(sshKeyId) {
    const sshKey = pageData.sshKeys.find(({ id }) => id === sshKeyId);
    openModal('removeKey', { sshKey });
  }

  onMount(loadUserKeys);
</script>

{#if pageData.displayedNotification}
  <InlineNotification hideCloseButton lowContrast {...notifications[pageData.displayedNotification]}>
    <div slot="actions">
      {#if notifications[pageData.displayedNotification].action}
        <NotificationActionButton on:click={notifications[pageData.displayedNotification].action?.onClick}>
          {notifications[pageData.displayedNotification].action?.text}
        </NotificationActionButton>
      {/if}
    </div>
  </InlineNotification>
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable {headers} rows={pageData.sshKeys ?? []} sortable pageSize={query.pageSize} page={query.currentPage}>
      <span class:action-cell={cell.key === 'actions'} slot="cell" let:cell let:row>
        {#if cell.key === 'publicKey'}
          <LongTextTooltipCell text={cell.value} />
        {:else if cell.key === 'sftp05Access'}
          <SshKeyAssociationStatus dataServiceAssociations={row.dataServiceAssociations} ownerType="user" serviceName="sftp05" />
        {:else if cell.key === 'atfAccess'}
          <SshKeyAssociationStatus dataServiceAssociations={row.dataServiceAssociations} ownerType="user" serviceName="atf" />
        {:else if cell.key === 'actions'}
          <OverflowMenu aria-label="Open and close list of options" flipped>
            <OverflowMenuItem text="Copy to clipboard" on:click={() => onCopyPublicKey(row.id)} />
            <OverflowMenuItem text="Edit key" on:click={() => onEditKey(row.id)} />
            <OverflowMenuItem danger text="Remove key" on:click={() => onRemoveKey(row.id)} />
          </OverflowMenu>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <Button size="small" on:click={() => openModal('addOrEditKey')}>Add user SSH key</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={query.currentPage}
      bind:pageSize={query.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={pageData.sshKeys?.length}
    />
  </LoadingSpinner>
{/if}
<AddOrEditSshKeyModal
  editingSshKey={modals.addOrEditKey.sshKey}
  ownerId={userId}
  ownerName={userFullName}
  ownerType="user"
  open={modals.addOrEditKey.open}
  on:close={() => closeModal('addOrEditKey')}
  on:submit={loadUserKeys}
/>
<RemoveSshKeyModal
  sshKeyId={modals.removeKey.sshKey?.id}
  ownerName={userFullName}
  ownerType="user"
  open={modals.removeKey.open}
  on:close={() => closeModal('removeKey')}
  on:submit={loadUserKeys}
/>
