<script>
  import { onMount } from 'svelte';

  import { Button, InlineNotification, Link, Toolbar, ToolbarContent } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import { DataTable } from '@mst-fe/carbon-components-svelte';

  import LoadingSpinner from '../../components/LoadingSpinner.svelte';
  import { getGroups } from '../../services';
  import { asOptionalTextColumn, rowContainsText, convertToLocalDisplayTime } from '../../utils';
  import PaginationWithRouting from '../../components/PaginationWithRouting.svelte';
  import ToolbarSearchWithRouting from '../../components/ToolbarSearchWithRouting.svelte';

  let pageData = { loading: true };
  let pagination = { currentPage: 1, pageSize: 25 };
  let searchText = '';
  let filteredRows = [];

  const navigate = useNavigate();

  onMount(async () => {
    try {
      const groups = await getGroups();
      pageData = { groups, loading: false };
    } catch (error) {
      console.error('[GroupList] Failed to load groups!', error);
      pageData = { loading: false, error };
    }
  });

  function onSearchTextChange(e) {
    // Note: we intentionally fall back on an empty string here since the event raised by clicking the "Clear" button
    // will not contain a value.
    searchText = e?.detail ?? '';
    pagination = { ...pagination, currentPage: 1 };
  }

  function viewGroupDetails(event, groupId) {
    event.preventDefault();
    navigate(`/group/${groupId}`);
  }

  $: if (pageData.groups) {
    filteredRows = searchText ? pageData.groups.filter((group) => rowContainsText(group, searchText)) : pageData.groups;
  }
</script>

{#if pageData.error}
  <InlineNotification hideCloseButton kind="error" lowContrast title="Error:" subtitle="Failed to load groups! Please try again later." />
{:else}
  <LoadingSpinner loading={pageData.loading}>
    <DataTable
      headers={[
        { key: 'name', value: 'Name' },
        { key: 'organizationName', value: 'Organization' },
        { key: 'shortName', value: 'Short Name', ...asOptionalTextColumn },
        { key: 'atfUsername', value: 'ATF Username', ...asOptionalTextColumn },
        { key: 'createdAt', value: 'Created', display: convertToLocalDisplayTime },
        { key: 'updatedAt', value: 'Updated', display: convertToLocalDisplayTime },
      ]}
      rows={filteredRows}
      sortable
      pageSize={pagination.pageSize}
      page={pagination.currentPage}
    >
      <span slot="cell" let:cell let:row>
        {#if cell.key === 'name'}
          <Link href={`/group/${row.id}`} on:click={(event) => viewGroupDetails(event, row.id)}>{row.name}</Link>
        {:else if cell.display}
          {cell.display(cell.value, row)}
        {:else}
          {cell.value}
        {/if}
      </span>
      <Toolbar>
        <ToolbarContent>
          <ToolbarSearchWithRouting
            labelText="Search for groups"
            persistent
            bind:searchText
            on:toolbarSearchValueChange={onSearchTextChange}
          />
          <Button on:click={() => navigate('/group/create')}>Create group</Button>
        </ToolbarContent>
      </Toolbar>
    </DataTable>
    <PaginationWithRouting
      bind:page={pagination.currentPage}
      bind:pageSize={pagination.pageSize}
      pageSizes={[10, 25, 50]}
      totalItems={filteredRows.length}
    />
  </LoadingSpinner>
{/if}
