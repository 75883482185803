<script>
  import {
    Accordion,
    AccordionItem,
    Button,
    Column,
    FormGroup,
    Grid,
    InlineNotification,
    Link,
    NumberInput,
    Row,
    StructuredList,
    StructuredListHead,
    StructuredListRow,
    StructuredListCell,
    StructuredListBody,
    TextArea,
    Checkbox,
  } from 'carbon-components-svelte';
  import Copy16 from 'carbon-icons-svelte/lib/Copy16';
  import copy from 'clipboard-copy';
  import ipRegex from 'ip-regex';
  import truncate from 'lodash/truncate';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import { TextInput } from '@mst-fe/carbon-components-svelte';
  import { Form } from '@mst-fe/sveltejs-forms';

  import DeleteGroupModal from './DeleteGroupModal.svelte';
  import LoadingButton from '../../components/LoadingButton.svelte';

  import { appConfig } from '../../stores';
  import { updateGroup } from '../../services';
  import { convertToLocalDisplayTime, getServerErrorMessage } from '../../utils';
  import { getCustomerBucketName } from '../../../shared/atf';

  export let groupId, group, loadGroupData;

  let formSubmitMessage,
    modals = {
      delete: { open: false },
    };

  const {
    flags: { atfEnabled },
    atf: { customerBucketNamePrefix: atfCustomerBucketNamePrefix },
  } = $appConfig.data;
  const ipValidator = ipRegex({ exact: true });
  const navigate = useNavigate();
  const initialValues = {
    ...group,
    licenseLookbackDays: group.licenseLookbackDays ?? 15,
  };

  const schema = yup.object().shape({
    name: yup.string().required('Enter the group name!'),
    shortName: yup.string().nullable(),
    aliases: yup.string().nullable(),
    sourceIpAddresses: yup
      .string()
      .nullable()
      .test('valid-ip', 'All entries must be valid IP addresses!', (value) =>
        value ? value.split(',').every((entry) => ipValidator.test(entry.trim())) : true
      ),
    addIpToAuthorizedKeys: yup.boolean(),
    sftp05Username: yup.string().nullable(),
    atfUsername: yup
      .string()
      .nullable()
      .matches(
        /^[\w][\w.-]{2,48}[\w]$/,
        'Username must start and end with letter, number, or underscore and can also contain the following in-between: . -'
      )
      .matches(/^(?!.*\.\.)/, 'Username cannot contain consecutive periods (..)')
      .min(3, 'Username must be at least 3 characters')
      .max(50, 'Username must be at most 50 characters long'),
    licenseLookbackDays: yup.number().min(0).integer(),
    notes: yup.string().nullable(),
  });

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    formSubmitMessage = undefined;

    try {
      await updateGroup(groupId, values);
      await loadGroupData();
      formSubmitMessage = {
        kind: 'success',
        title: 'Success:',
        subtitle: 'Group updated successfully!',
      };
    } catch (error) {
      const errorMessage = getServerErrorMessage(error) ?? 'Verify your submission and try again.';
      console.error('[GroupBasicInformation] Failed to update group data!', errorMessage);
      formSubmitMessage = {
        kind: 'error',
        title: 'Error:',
        subtitle: `Failed to update group! ${errorMessage}`,
      };
    } finally {
      setSubmitting(false);
      document.querySelector('#main-content').scrollTo(0, 0);
    }
  }

  function viewOrganizationDetails(event, organizationId) {
    event.preventDefault();
    navigate(`/organization/${organizationId}`);
  }

  function updatePageData(modification) {
    const { action, data } = modification;

    if (!data) {
      return;
    }

    switch (action) {
      case 'delete-group':
        formSubmitMessage = {
          kind: 'success',
          title: 'Success:',
          subtitle: `Group ${data.name} deleted successfully! Redirecting...`,
        };

        setTimeout(() => navigate('/groups'), 2500);
        break;

      default:
        console.warn(`[GroupBasicInformation] Unknown action "${action}" on modal close. Define action's expected behavior. Data:`, data);
    }
  }

  function handleClickForModal(name, forwardData = {}) {
    return function openModal() {
      modals = {
        ...modals,
        [name]: { open: true, ...forwardData },
      };
    };
  }

  function closeModal(name, modification = null) {
    if (modification) {
      updatePageData(modification);
    }

    modals = {
      ...modals,
      [name]: { open: false },
    };
  }
</script>

{#if formSubmitMessage}
  <InlineNotification kind={formSubmitMessage.kind} lowContrast title={formSubmitMessage.title} subtitle={formSubmitMessage.subtitle} />
{/if}
<Grid noGutterLeft noGutterRight padding>
  <Row>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading">Read-Only Fields</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Group ID</StructuredListCell>
            <StructuredListCell>
              <div class="id-controls">
                {truncate(group.id, { length: 20 })}
                <Button
                  class="copy-button"
                  hasIconOnly
                  icon={Copy16}
                  iconDescription="Copy to clipboard"
                  kind="ghost"
                  size="small"
                  on:click={() => copy(group.id)}
                />
              </div>
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Parent Organization</StructuredListCell>
            <StructuredListCell>
              <Link
                href={`/organization/${group.organization?.id}`}
                on:click={(event) => viewOrganizationDetails(event, group.organization?.id)}
              >
                {group.organization?.name}
              </Link>
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
      <h2 class="column-heading">Metadata</h2>
      <StructuredList condensed flush>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell head>Attribute</StructuredListCell>
            <StructuredListCell head>Value</StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell noWrap>Created</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(group.createdAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Created By</StructuredListCell>
            <StructuredListCell>{group.createdByDisplayName}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Last Updated</StructuredListCell>
            <StructuredListCell>{convertToLocalDisplayTime(group.updatedAt)}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell noWrap>Updated By</StructuredListCell>
            <StructuredListCell>{group.updatedByDisplayName}</StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredList>
    </Column>
    <Column sm={4} md={8} lg={8}>
      <h2 class="column-heading column-heading-form">Editable Fields</h2>
      <Form
        {schema}
        {initialValues}
        validateOnBlur={true}
        validateOnChange={true}
        on:submit={onFormSubmit}
        let:setValue
        let:values
        let:submitForm
        let:isSubmitting
        let:errors
        let:touched
      >
        <FormGroup>
          <TextInput
            helperText="Capitalized, user-friendly name unless purposely stylized otherwise by the customer. Example: Client Services."
            invalid={touched.name && !!errors.name}
            invalidText={errors.name}
            labelText="Group Name"
            name="name"
            placeholder="Group name"
            type="text"
            value={values.name}
            required
            on:change={({ detail: text }) => setValue('name', text)}
          />
          <TextInput
            helperText="Used to uniquely identify the group in email chains and Jenkins. Example: mst_cs."
            invalid={touched.shortName && !!errors.shortName}
            invalidText={errors.shortName}
            labelText="Short Name"
            name="shortName"
            placeholder="Short name"
            type="text"
            value={values.shortName}
            on:change={({ detail: text }) => setValue('shortName', text)}
          />
          <TextInput
            helperText="Alternative or previous names for this group."
            invalid={touched.aliases && !!errors.aliases}
            invalidText={errors.aliases}
            labelText="Aliases"
            name="aliases"
            placeholder="Comma-separated aliases"
            type="text"
            value={values.aliases}
            on:change={({ detail: text }) => setValue('aliases', text)}
          />
          <NumberInput
            helperText="Used to prematurely notify that a license is about to expire."
            invalid={touched.licenseLookbackDays && !!errors.licenseLookbackDays}
            invalidText="License lookback days must be a positive integer!"
            label="License lookback days"
            min={0}
            name="licenseLookbackDays"
            placeholder="15"
            value={values.licenseLookbackDays}
            on:change={({ detail: value }) => setValue('licenseLookbackDays', value)}
            on:input={({ detail: value }) => setValue('licenseLookbackDays', value)}
          />
          <h3 class="column-subheading">SFTP05</h3>
          <TextInput
            invalid={touched.sftp05Username && !!errors.sftp05Username}
            invalidText="This SFTP05 username is already assigned to another group!"
            labelText="SFTP05 Username"
            name="sftp05Username"
            placeholder="SFTP05 username"
            type="text"
            value={values.sftp05Username}
            disabled={group.sftp05Username}
            on:change={({ detail: text }) => setValue('sftp05Username', text)}
          />
          <TextInput
            helperText="Use commas to separate entries."
            invalid={touched.sourceIpAddresses && !!errors.sourceIpAddresses}
            invalidText={errors.sourceIpAddresses}
            labelText="Source IP Addresses"
            name="sourceIpAddresses"
            placeholder="Source IP addresses"
            type="text"
            value={values.sourceIpAddresses}
            on:change={({ detail: text }) => setValue('sourceIpAddresses', text)}
          />
          <Checkbox
            name="addIpToAuthorizedKeys"
            labelText="Add source IP Addresses to authorized keys"
            checked={values.addIpToAuthorizedKeys}
            on:check={({ detail }) => setValue('addIpToAuthorizedKeys', detail)}
          />
          {#if atfEnabled}
            <h3 class="column-subheading">AWS Transfer Family</h3>
            <TextInput
              invalid={touched.atfUsername && !!errors.atfUsername}
              invalidText={errors.atfUsername}
              labelText="ATF Username"
              name="atfUsername"
              placeholder="ATF Username"
              type="text"
              value={values.atfUsername}
              disabled={group.atfUsername}
              on:change={({ detail: text }) => setValue('atfUsername', text)}
            />
            {#if group.atfUsername}
              <div class="atf-details">
                <Accordion align="start" size="sm">
                  <AccordionItem title="Details">
                    <div class="accordion-item">
                      <div>
                        <TextInput readonly labelText="Role Name" size="sm" value={group.awsIamRoleName} />
                        <Button
                          class="copy-button"
                          hasIconOnly
                          icon={Copy16}
                          iconDescription="Copy to clipboard"
                          kind="ghost"
                          size="small"
                          on:click={() => copy(group.awsIamRoleName)}
                        />
                      </div>
                      <div>
                        <TextInput
                          readonly
                          labelText="Bucket Name"
                          size="sm"
                          value={getCustomerBucketName(atfCustomerBucketNamePrefix, group.id)}
                        />
                        <Button
                          class="copy-button"
                          hasIconOnly
                          icon={Copy16}
                          iconDescription="Copy to clipboard"
                          kind="ghost"
                          size="small"
                          on:click={() => copy(getCustomerBucketName(atfCustomerBucketNamePrefix, group.id))}
                        />
                      </div>
                    </div>
                  </AccordionItem>
                </Accordion>
              </div>
            {/if}
          {/if}
          <h3 class="column-subheading">Miscellaneous</h3>
          <TextArea
            invalid={touched.notes && !!errors.notes}
            invalidText={errors.notes}
            labelText="Notes"
            name="notes"
            placeholder="Additional notes"
            type="text"
            value={values.notes}
            on:change={({ target }) => setValue('notes', target.value)}
          />
        </FormGroup>
        <div class="form-controls">
          <LoadingButton kind="primary" on:click={submitForm} disabled={isSubmitting} isLoading={isSubmitting}>Save</LoadingButton>
        </div>
      </Form>
      <h2 class="column-heading column-heading-additional-actions">Additional Actions</h2>
      <div class="form-controls">
        <p class="text-info">
          Delete group and all related entities. <strong>Attention! This action is irreversible.</strong>
        </p>
        <Button kind="danger" on:click={handleClickForModal('delete')}>Delete Group</Button>
      </div>
    </Column>
  </Row>
</Grid>

{#if modals.delete.open}
  <DeleteGroupModal
    open
    groupId={group.id}
    groupName={group.name}
    on:close={({ detail: data }) =>
      closeModal('delete', {
        action: 'delete-group',
        data,
      })}
  />
{/if}

<style>
  /* For accessibility, keep the column-heading elements as h2 tags, but style them similar to h4 */
  .column-heading {
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  .column-heading + :global(.bx--structured-list) {
    margin-bottom: 2.5rem;
  }
  .column-heading + :global(.bx--structured-list:last-child) {
    margin-bottom: 0.5rem;
  }
  .column-heading + :global(.bx--structured-list .bx--structured-list-td) {
    width: 50%;
  }

  .column-heading-form + :global(.sveltejs-forms > .bx--fieldset > .bx--form-item:first-child) {
    margin: 0 0 0.75rem;
  }

  @media screen and (min-width: 66rem) {
    .column-heading-form {
      margin-bottom: 1.875rem;
    }
    .column-heading-form + :global(.sveltejs-forms) {
      margin-top: 0;
    }
  }

  .column-heading-additional-actions {
    margin-top: 2.5rem;
  }

  .column-subheading {
    font-size: 1rem;
    margin-top: 1.25rem;
  }

  .id-controls :global(.bx--btn) {
    min-height: unset;
    padding-top: 0;
  }
  .id-controls :global(.bx--btn svg > path) {
    fill: var(--cds-link-01) !important;
  }

  .text-info {
    color: var(--cds-text-02);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .atf-details :global(.bx--accordion__item:last-child) {
    border: none !important;
  }

  .atf-details :global(.bx--label) {
    margin-bottom: 0.25rem;
  }

  .atf-details .accordion-item > div {
    display: flex;
    align-items: flex-end;
  }

  .atf-details .accordion-item :global(.bx--text-input-wrapper--readonly) {
    margin-top: 0.5rem;
  }

  .atf-details .accordion-item :global(.bx--text-input-wrapper--readonly input) {
    background-color: var(--cds-field-01);
  }
</style>
