<script>
  import { onMount } from 'svelte';

  import { InlineNotification, NotificationActionButton } from 'carbon-components-svelte';
  import { useNavigate } from 'svelte-navigator';

  import DataFeedInventoryRegressionModal from './regression-modal/DataFeedInventoryRegressionModal.svelte';

  import ServiceStatusCard from './ServiceStatusCard.svelte';
  import LoadingSpinner from '../../../components/LoadingSpinner.svelte';
  import { getDataFeedInventoryServiceStatus } from '../../../services';

  const navigate = useNavigate();

  let displayedNotification,
    loading = true,
    services = [],
    resolutionModalOpen = false,
    resolutionModalServiceId;

  async function getServiceStatus() {
    displayedNotification = undefined;
    loading = true;
    try {
      const rawServices = await getDataFeedInventoryServiceStatus();
      services = rawServices.filter(({ id }) => id !== 1).sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('[ServiceStatusList] Failed to retrieve service status list!', error);
      displayedNotification = {
        kind: 'error',
        title: 'Error:',
        subtitle: 'Failed to retrieve service status list! Please try again later.',
      };
    } finally {
      loading = false;
    }
  }

  async function onRegressionResolved({ detail }) {
    toggleReviewRegressionModal();
    await getServiceStatus();

    if (displayedNotification) {
      // If we failed to refresh service status after the regression resolved, show that error message instead.
      return;
    }

    const triggeredTaskNames = detail.triggeredTasks?.map(({ taskName }) => taskName);
    displayedNotification = {
      kind: 'success',
      title: 'Success:',
      subtitle: triggeredTaskNames
        ? `Regression resolved. The following synchronization tasks have been triggered: ${triggeredTaskNames.join(', ')}.`
        : 'Regression resolved.',
      action: triggeredTaskNames ? { text: 'Execution Page', onClick: viewTaskExecutions } : undefined,
    };
  }

  function toggleReviewRegressionModal(event) {
    resolutionModalOpen = !resolutionModalOpen;
    resolutionModalServiceId = event?.detail?.serviceId;
  }

  function viewTaskExecutions() {
    navigate('/tasks#executions');
  }

  onMount(getServiceStatus);
</script>

<h2 class="section-heading">Service Status</h2>
{#if displayedNotification}
  <InlineNotification lowContrast {...displayedNotification}>
    <div slot="actions" class="actions">
      {#if displayedNotification?.action}
        <NotificationActionButton on:click={displayedNotification?.action?.onClick}>
          {displayedNotification?.action?.text}
        </NotificationActionButton>
      {/if}
    </div>
  </InlineNotification>
{/if}
<p class="text-info">
  The section below shows the last sync status of a given service. Regressions should be resolved as quickly as possible in order to prevent
  data access interruptions. Regressions should only be resolved by administrators or Data Team members; future releases will ensure this by
  adding role-based permissions.
</p>
<LoadingSpinner {loading} withOverlay={services.length > 0}>
  <div class="services">
    {#each services as service}
      <ServiceStatusCard {service} on:reviewRegression={toggleReviewRegressionModal} />
    {/each}
  </div>
</LoadingSpinner>
<DataFeedInventoryRegressionModal
  open={resolutionModalOpen}
  serviceId={resolutionModalServiceId}
  on:close={toggleReviewRegressionModal}
  on:regressionResolved={onRegressionResolved}
/>

<style>
  /* For accessibility, keep the section-heading element as h2 tags, but style similar to h4 */
  .section-heading {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .text-info {
    color: var(--cds-text-02);
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .text-info + :global(.loading-container) {
    min-height: 6rem;
  }

  .services {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    margin: 2rem 0 7rem; /* if we add overflow menu to cards, the margin-bottom prevents added vertical scroll */
    row-gap: 1.5rem;
  }
</style>
